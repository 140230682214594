import { Header } from "../Header";
import {
  addCheckInTimeInFormattedDate,
  addCheckOutTimeInFormattedDate,
} from "../../utils";

const Android = (props) => {
  const {
    reservation: {
      checkin_date,
      checkout_date,
      property_name,
      room_no,
      room_qr_image,
    },
    qrCode,
    showOverlay,
  } = props;
  const checkInDateTime = addCheckInTimeInFormattedDate(checkin_date);
  const checkOutDateTime = addCheckOutTimeInFormattedDate(checkout_date);

  return (
    <div className={`andriod-page-wrapper ${showOverlay && "blurredBg"} `}>
      <Header />
      <div className="qr-code-wrap">
        <img src={qrCode} alt="" />
      </div>
      <div className="hotel-details-wrap">
        <div className="hotel-detail">
          <label className="title">hotel</label>
          <span className="about-hotel">Hollywood Hills Hotel</span>
        </div>
        <div className="room-details">
          <label className="title">room number</label>
          <span className="about-hotel">
            {process.env.REACT_APP_ENV === "dev" ? "117 - Accessible" : room_no}
          </span>
        </div>
        <div className="qr-validity">
          <label className="title">QR Code Validity</label>
          <span className="about-hotel">
            {checkInDateTime} - {checkOutDateTime}
          </span>
        </div>
      </div>

      <div className="alert-div">
        <span className="alert-text">
          Do not screenshot, it will not work as the code changes.
        </span>
      </div>
    </div>
  );
};

export default Android;
